<template>
  <v-card>
    <v-card-title>Supplier</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$store.getters['auth/can'](['create pre-repairs'])"
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="saveSupplier"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.supplier_name"
              label="Supplier Name"
              outlined
              dense
              :error-messages="errors.supplier_name"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.address"
              label="Address"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.owner_first_name"
              :error-messages="errors.owner_first_name"
              label="Owner First Name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.owner_last_name"
              :error-messages="errors.owner_last_name"
              label="Owner Last Name"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.tel_number"
              label="Tel No"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.cell_number"
              label="Cell No"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.email"
              label="Email"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.tin"
              label="Tin"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiPlus, mdiCalendarCheckOutline, mdiSpeedometer, mdiClose, mdiCogOutline,
} from '@mdi/js'
import axios from '@axios'
import router from '@/router'
import useSuppliers from './useSuppliers'

const formInit = () => ({
  supplier_name: null,
  owner_first_name: null,
  owner_last_name: null,
  tel_number: null,
  cell_number: null,
  address: null,
  tin: null,
})
export default {
  setup() {
    const saving = ref(false)
    const supplierTab = ref(null)
    const form = ref(formInit())
    const errors = ref([])

    const { supplier, getSupplier } = useSuppliers()
    const resetForm = () => {
      form.value = formInit()
    }

    const saveSupplier = () => {
      saving.value = true
      if (form.value.id) {
        axios
          .put(`/suppliers/${form.value.id}`, form.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'supplier' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        axios
          .post('/suppliers', form.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'supplier' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    if (router.currentRoute.params.id) {
      saving.value = true
      getSupplier(router.currentRoute.params.id).then(response => {
        const { data } = response
        form.value = data
        saving.value = false
      })
    }

    return {
      saving,
      supplierTab,
      form,
      errors,

      supplier,
      getSupplier,

      resetForm,
      saveSupplier,

      // icons
      icons: {
        mdiPlus,
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>
