import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useSupplier() {
  const suppliers = ref([])
  const supplier = ref({})
  const loading = ref(false)
  const searchSupplier = ref(null)

  const getSuppliers = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/suppliers/filter', params)
        .then(response => {
          const { data } = response.data
          suppliers.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getSupplier = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/suppliers/${id}`)
        .then(response => {
          const { data } = response
          supplier.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchSupplier,
    suppliers,
    supplier,

    // functions
    getSuppliers,
    getSupplier,
  }
}
